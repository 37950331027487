import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Card from '@mui/material/Card';

// custom components
import { Layout } from '../../components/layout/CatalogDefault';
import { MoreButton } from '../../components/catalog/more-button';
import StyledBox from '../../components/controls/StyledBox';
import Content from '../../custom/segments/Content';
import Gallery from '../../custom/segments/Gallery';
import Downloads from '../../custom/segments/Downloads';
import Newsletter from '../../custom/segments/NewsletterSimple';
import ProductCollection from '../../custom/segments/Products';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

export const query = graphql`
  query ($id: String, $catalog: String) {
    triptype: datoCmsTriptype(id: { eq: $id }) {
      id
      originalId
      title
      alias
      teaser
      content
      footer
      catalogs {
        id
        title
      }
      coverImage {
        gatsbyImageData(width: 1600)
        alt
      }
      gallery {
        alt
        basename
        fixed(width: 250) {
          src
        }
        gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.3)
      }
      downloads {
        title
        basename
        url
        customData
        size
        format
        filename
        alt
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    products: allDatoCmsItem(
      filter: {
        catalogs: { elemMatch: { id: { eq: $catalog } } }
        triptypes: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: [featured, weight, title], order: [DESC, DESC, ASC] }
      limit: 24
    ) {
      edges {
        node {
          ...ProductWithImageCard
        }
      }
      pageInfo {
        hasNextPage
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default function TriptypePage({ data, pageContext }) {
  const { triptype, products, site } = data;
  const { id, catalogType, catalog } = pageContext;
  const coverHeader = triptype && triptype.alias ? triptype.alias : triptype.title;
  const allProducts = products.edges;

  const showNewsletter = config.segments.newsletter.enabled;

  const segmentType = config.catalog.triptype.querykey;
  const segmentKey = triptype.originalId;

  const galleryImages = nodesUtils.getGalleryImages(triptype.gallery);
  const hasContent = triptype && (triptype.teaser || triptype.content);
  const pageParams = {
    title: coverHeader,
    cover: { ...triptype.coverImage },
  };

  return (
    <Layout catalogType={catalogType} catalogId={catalog} pageParams={pageParams}>
      <HelmetDatoCms seo={triptype.seo} favicon={site.favicon} />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: -8,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StyledBox mb={2}>
          <ProductCollection
            products={allProducts}
            header={'Unsere Angebote'}
            catalogType={catalogType}
          />
          {products.pageInfo.hasNextPage && (
            <MoreButton
              segmentType={segmentType}
              segmentKey={segmentKey}
              catalogType={catalogType}
              catalogId={catalog}
            >
              Mehr anzeigen
            </MoreButton>
          )}
        </StyledBox>
        {hasContent && (
          <StyledBox py={{ xs: 3, md: 6 }}>
            <Content teaser={triptype.teaser} body={triptype.content} footer={triptype.footer} />
          </StyledBox>
        )}
        <Downloads header={'Downloads'} downloads={triptype.downloads} />
        <Gallery header={'Bildergalerie'} images={galleryImages} />
        {showNewsletter && <Newsletter />}
      </Card>
    </Layout>
  );
}
